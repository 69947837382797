import React from "react"
import dataInfo from "../Data"
import straightArrow from "../assets/straightarrow.svg"
import middleArrow from "../assets/middlearrow.svg"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

export default function Education(){
    const [ref, inView] = useInView()
    const imageAlt = "arrow pointing downwards to next achievement"
    const containerStyling = {
        padding:"1em",
        borderRadius: "20px",
        margin: "1.5em 1em",
        display:"flex",
        flexDirection: "column",
    }
    const textStyling = {
        background:"#0c3b2e", 
        width:"80%", 
        padding:"0.75em", 
        color: "#fffbf2"
    }

    return (
        <div
            className="education-component" 
            style={containerStyling}
        >
            <h3>
                Education
            </h3>

            <p 
                style={{...textStyling, borderRadius:"21px", margin:"1em auto 1em 0"}}
            >
                {dataInfo.personalDetails.education.certifications[0]} 
            </p>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: "-20px" }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : "-20px" }}
                transition={{ duration: 0.4 }}
                style={{display:'flex', paddingLeft:'8em'}}
            >
                <img 
                    src={middleArrow} 
                    alt={imageAlt} 
                    style={{width:"30px", margin:"0", rotate:"60deg"}} 
                />
            </motion.div>

            <p 
                style={{...textStyling, borderRadius:"21px", margin:"1em auto 1em 0.5em"}}
            >
                {dataInfo.personalDetails.education.certifications[1]} 
            </p>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, x: "30px" }}
                animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : "30px" }}
                transition={{ duration: 1.4 }}
                style={{display:'flex', paddingLeft:'4em'}}
            >
                <img 
                    src={straightArrow} 
                    alt={imageAlt} 
                    style={{width:"30px", margin:"0", rotate:"-80deg"}} 
                />
            </motion.div>

            <p 
                style={{...textStyling, borderRadius:"21px", margin:"1em auto 1em 1em"}}
            >
                {dataInfo.personalDetails.education.certifications[2]} 
            </p>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: "20px" }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : "20px" }}
                transition={{ duration: 2.1 }}
                style={{display:'flex', paddingLeft:'10em'}}
            >
                <img 
                    src={straightArrow} 
                    alt={imageAlt} 
                    style={{width:"30px", margin:"0", rotate:"20deg"}} 
                />
            </motion.div>

            <p 
                style={{...textStyling, borderRadius:"21px", margin:"1em auto 1em 1.5em"}}
            > 
                {dataInfo.personalDetails.education.certifications[3]} 
            </p>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, x: "30px" }}
                animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : "30px" }}
                transition={{ duration: 3.0 }}
                style={{display:'flex', paddingLeft:'13em'}}
            >
                <img 
                    src={middleArrow} 
                    alt={imageAlt} 
                    style={{width:"30px", margin:"0", rotate:"10deg"}} 
                />
            </motion.div>

            <p 
                style={{...textStyling, borderRadius:"21px", margin:"1em auto 1em 2em"}}
            > 
                {dataInfo.personalDetails.education.certifications[4]} 
            </p>
        </div>
    )
}