import React from "react"
import dataInfo from "../Data"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

export default function About(){    
    const [ref, inView] = useInView()
    const containerStyling = {
        padding:"1em",
        borderRadius: "20px",
        margin: "1em",
    }

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, x: "-60px" }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : "-60px" }}
            transition={{ duration: 1.1 }}
            className="about-component" 
            style={containerStyling}
        >
            <h3>About Me</h3>
            <p>
                {dataInfo.personalDetails.biography.firstParagraph}
            </p>
            <p style={{marginBottom:"0"}}>
                {dataInfo.personalDetails.biography.secondParagraph}
            </p>
        </motion.div>
    )
}