import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import dataInfo from "../Data"
import { motion } from "framer-motion"

export default function Footer(){
    const containerStyling = {
        padding:"1em",
        borderRadius: "20px",
        margin: "1em",
        cursor:'pointer'
    }
    const linkStyling = {
        textDecoration:"none",
        color:"inherit",
        display:"block"
    }

    return (
        <motion.div
            initial={{ scale: 1.0 }}
            whileHover={{ scale: 1.04 }}
            className="footer-component"
            style={containerStyling}
        >
            <a href="mailto:contact@m-gagliardi.com" style={linkStyling}>
                <h3>Contact</h3>
                <p>Have an exciting project you need help with? Send me an email and let's start building!</p>
                <p>
                    <FontAwesomeIcon 
                        icon={faEnvelope} 
                        style={{ margin: "auto 0.5em auto auto" }}
                    />
                    {dataInfo.personalDetails.email}
                </p>
            </a>
        </motion.div>
    )
}