import React from "react"
import dataInfo from "../Data"
import pythonLogo from "../assets/python_logo.png"
import javascriptLogo from "../assets/javascript_logo.png"

export default function SkillSet(){
    const containerStyling = {
        borderRadius: "20px",
        margin: "1.5em 1em",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "1em"
    }

    return (
        <div className="skill-set-component" style={containerStyling}>
            <h3>Skills</h3>
            <div style={{display:"flex", clipPath:"polygon(0 0, 100% 0, 84% 100%, 0% 100%)", padding:"3em 12em 3em 5em", borderRadius:"8px"}}>
                <img 
                    src={javascriptLogo} 
                    style={{margin:"auto 3em auto 0", height:"110px", paddingTop:"0.5em"}}
                    alt="javascript programming language logo"
                />
                <div className="skill-set-shape" style={{margin:"auto auto auto 0"}}>
                    <h4 style={{fontSize:"1.5rem"}}>
                        JavaScript
                    </h4>
                    <p style={{fontSize:"1.2rem"}}>
                        React  -  TypeScript  -  Node.js
                    </p>
                </div>
            </div>
            <div style={{display:"flex", clipPath:"polygon(16% 0, 100% 0, 100% 100%, 0 100%)", padding:"3em 5em 3em 9em", borderRadius:"8px" }}>
                <img 
                    src={pythonLogo} 
                    style={{margin:"auto 3em auto 0", height:"110px", paddingTop:"0.5em"}}
                    alt="python programming language logo"
                />
                <div>
                    <h4 style={{fontSize:"1.5rem"}}>
                        Python
                    </h4>
                    <p style={{fontSize:"1.2rem"}}>
                        Django  -  Flask  -  NumPy  -  Pandas
                    </p>
                </div>
            </div>
        </div>
    )
}