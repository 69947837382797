import React from "react"
import character from "../assets/dude.svg"
import dataInfo from "../Data"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

export default function Profile() {
    const [ref, inView] = useInView()
    const containerStyling = {
        padding:"1em",
        borderRadius: "20px",
        margin: "1em",
        display: "flex",
        flexDirection: "column",
        justifyContent : "center",
        alignItems: "center"
    }
    const textStyling = {
        margin: "0.75em auto", 
        fontWeight:"bold"
    }
    const linkStyling = {
        textDecoration:"none",
        color:"inherit"
    }

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, x: "60px" }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : "60px" }}
            transition={{ duration: 1.1 }}
            className="profile-component" 
            style={containerStyling}
        >
            <img src={character} alt="cartoon depiction of Michael smiling" style={{marginBottom:"2em", width:"210px"}}/>
            <p style={textStyling}>Michael Gagliardi</p>
            <p style={textStyling}>
                <a href="mailto:contact@m-gagliardi.com" style={linkStyling}>
                    {dataInfo.personalDetails.email}
                </a>
            </p>
        </motion.div>
    )
}