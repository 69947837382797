import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons"

export default function SingleProject(props) {  
    const containerStyling = {
        margin: "2em auto",
        cursor: "pointer",
        width: "310px"
    }

    const imageStyling = {
        width:"300px", 
        height: "205px",
        borderRadius:"20px",
    }
    return (
        <div className="single-project-component" style={containerStyling}>
            <img src={props.image} alt={`${props.title}'s User Interface`} style={imageStyling}/>
            <div className="single-project-component-bottom" style={{display:"flex"}}>
                <h4 style={{width:"max-content"}}>{props.title}</h4>
                <FontAwesomeIcon icon={faArrowTrendUp} style={{margin:"auto 1em auto auto"}}/>
            </div>
        </div>
    )
}
